import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { RemotePartnershipRepo } from '@repository/partnership/PartnershipRepo';
import { AllianceInfoVO } from './../../domain/vo/alliance/AllianceInfoVO';
import AffiliatedUniversitySaveRq, { AffiliatedUniversityUpdateRq } from '@domain/rq/alliance/AffiliatedUniversityRq';
import { AffiliatedUniversityInfoUpdateVO } from '@domain/vo/alliance/AffiliatedUniversityInfoVO';
import { PARTNERSHIP_TYPE } from '@domain/rs/alliance/AffiliateListRs';
import { TERMS_TYPE } from '@domain/constant/v1/termsType';

const remotePartnershipRepo = new RemotePartnershipRepo();

const keys = ['partnership'];
export const useAffilliateCertificationStatusKeys = [...keys, 'user', 'info'];

//회원 제휴 인증현황 조회
const fetchAffiliateCertificationStatus = async () => {
  const { data } = await remotePartnershipRepo.fetchAffiliateCertificationStatus();
  return new AllianceInfoVO(data);
};

export function useAffilliateCertificationStatus(options?: UseQueryOptions) {
  return useQuery(useAffilliateCertificationStatusKeys, fetchAffiliateCertificationStatus, {
    ...(options as any),
  });
}

//중복 학번여부 조회
const fetchDuplicateStudentIdCheck = async (schoolCode: number, studentId: string) => {
  const { data } = await remotePartnershipRepo.fetchDuplicateStudentIdCheck(schoolCode, studentId);
  return data;
};

export function useDuplicateStudentIdCheck(onError?: (e: AxiosError) => void) {
  return useMutation(
    ({ schoolCode, studentId }: { schoolCode: number; studentId: string }) =>
      fetchDuplicateStudentIdCheck(schoolCode, studentId),
    { onError },
  );
}

//제휴 대학 인증 정보 저장
const saveAffiliatedUniversityInfo = async (rq: AffiliatedUniversitySaveRq) => {
  const { data } = await remotePartnershipRepo.fetchSaveAffiliatedUniversityInfo(rq);
  return data;
};

export function useSaveAffiliatedUniversityInfo() {
  return useMutation((data: AffiliatedUniversitySaveRq) => saveAffiliatedUniversityInfo(data));
}

//제휴 대학 이메일  인증
const fetchUniversityEmailCheck = async (email: string, token: string) => {
  const { data } = await remotePartnershipRepo.fetchUniversityEmailCheck(email, token);
  return data;
};

export function useUniversityEmailCheck() {
  return useMutation(({ email, token }: { email: string; token: string }) => fetchUniversityEmailCheck(email, token));
}

//제휴 대학 이메일 인증코드 발송
const fetchUniversityEmailCodeSend = async (schoolCode: number, email: string) => {
  const { data } = await remotePartnershipRepo.fetchUniversityEmailCodeSend(schoolCode, email);
  return data;
};

export function useUniversityEmailCodeSend(onError?: (e: AxiosError) => void) {
  return useMutation(
    ({ schoolCode, email }: { schoolCode: number; email: string }) => fetchUniversityEmailCodeSend(schoolCode, email),
    {
      onError,
    },
  );
}

//제휴 대학 리스트 조회
const fetchAffiliatedUniversities = async (name: string) => {
  const { data } = await remotePartnershipRepo.fetchAffiliatedUniversities(name);
  return data;
};

export function useAffiliatedUniversities() {
  return useQuery([...keys, 'universities'], () => fetchAffiliatedUniversities(''));
}

//제휴서비스 약관
const fetchPartnershipTermsList = async (termType: TERMS_TYPE) => {
  const { data } = await remotePartnershipRepo.fetchPartnershipTermsList(termType);
  return data;
};

export function usePartnershipTermsList() {
  return useMutation((termType: TERMS_TYPE) => fetchPartnershipTermsList(termType));
}

//제휴 기관 인증 정보 저장
const fetchSaveVerificationCodeInfo = async (code: string) => {
  const { data } = await remotePartnershipRepo.fetchSaveVerificationCodeInfo(code);
  return data;
};

export function useSaveVerificationCodeInfo() {
  return useMutation((code: string) => fetchSaveVerificationCodeInfo(code));
}

//제휴 기관 인증코드 인증
const fetchVerificationCodeCheck = async (code: string) => {
  const { data } = await remotePartnershipRepo.fetchVerificationCodeCheck(code);
  return data;
};

export function useVerificationCodeCheck(onError?: (e: AxiosError) => void) {
  return useMutation((code: string) => fetchVerificationCodeCheck(code), { onError });
}

//제휴 대학 인증 정보 수정
const fetchUpdateAffiliatedUniversity = (vo: AffiliatedUniversityInfoUpdateVO) => {
  const rq = new AffiliatedUniversityUpdateRq(vo);
  return remotePartnershipRepo.fetchUpdateAffiliatedUniversity(rq);
};

export function useUpdateAffiliatedUniversity() {
  return useMutation((vo: AffiliatedUniversityInfoUpdateVO) => fetchUpdateAffiliatedUniversity(vo));
}

//제휴 리스트 조회
const fetchAffiliateList = async (partnershipType?: PARTNERSHIP_TYPE) => {
  const { data } = await remotePartnershipRepo.fetchAffiliateList(partnershipType);
  return data;
};

export function useAffiliateList(partnershipType?: PARTNERSHIP_TYPE, enabled?: boolean) {
  return useQuery([...keys, 'list', partnershipType], () => fetchAffiliateList(partnershipType), {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  });
}

// 제휴 인증 해지
const fetchDeletePartnershipUser = async (partnershipType: PARTNERSHIP_TYPE) => {
  const { data } = await remotePartnershipRepo.fetchDeletePartnershipUser(partnershipType);
  return data;
};

export const useDeletePartnershipUser = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation((partnershipType: PARTNERSHIP_TYPE) => fetchDeletePartnershipUser(partnershipType), {
    onSuccess: onSuccess,
  });
};
