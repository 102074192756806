import AllianceInfoRs, {
  AffiliatedUniversityInfoDto,
  AffiliatedOrganizationInfoDto,
} from '@domain/rs/alliance/AllianceInfoRs';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';
import DateFormat from '@utils/date/format';

export class AllianceInfoVO {
  universityVerified: boolean;
  organizationVerified: boolean;
  universityInfo: AffiliatedUniversityInfoVO | null;
  organizationInfo: AffiliatedOrganizationInfoVO | null;

  constructor(rs: AllianceInfoRs) {
    this.universityVerified = rs.universityVerified;
    this.organizationVerified = rs.organizationVerified;
    this.universityInfo = rs.universityInfo ? new AffiliatedUniversityInfoVO(rs.universityInfo) : null;
    this.organizationInfo = rs.organizationInfo ? new AffiliatedOrganizationInfoVO(rs.organizationInfo) : null;
  }
}

export class AffiliatedUniversityInfoVO {
  verifiedDate: string;
  schoolCode: number;
  schoolName: string;
  major: ReactSelectOption;
  studentId: string;

  constructor(rs: AffiliatedUniversityInfoDto) {
    this.verifiedDate = DateFormat.format(new Date(rs.verifiedDate), 'yyyy.MM.dd');
    this.schoolCode = rs.schoolCode;
    this.schoolName = rs.schoolName;
    this.major = { label: rs.majorName, value: rs.majorCode };
    this.studentId = rs.studentId;
  }
}

export class AffiliatedOrganizationInfoVO {
  verifiedDate: string;
  organizationName: string;

  constructor(rs: AffiliatedOrganizationInfoDto) {
    this.verifiedDate = DateFormat.format(new Date(rs.verifiedDate), 'yyyy.MM.dd');
    this.organizationName = rs.organizationName;
  }
}
