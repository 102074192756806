import { AxiosResponse } from 'axios';
import { DuplicatedRs } from '@domain/rs/common/v1/DuplicateRs';
import { axios } from '@repository/RequestClient';
import AffiliatedUniversitySaveRq, { AffiliatedUniversityUpdateRq } from '@domain/rq/alliance/AffiliatedUniversityRq';
import AffiliateListRs, { PARTNERSHIP_TYPE } from '@domain/rs/alliance/AffiliateListRs';
import AffiliatedUniversitiesRs from '@domain/rs/alliance/AffiliatedUniversitiesRs';
import PartnershipRs from '@domain/rs/alliance/PartnershipRs';
import AllianceInfoRs from '@domain/rs/alliance/AllianceInfoRs';
import { TermsOneRs } from '@domain/rs/terms/v1/TermsRs';
import { TERMS_TYPE } from '@domain/constant/v1/termsType';

const partnershipPrefix = '/partnership';

export default interface PartnershipRepo {
  //제휴 리스트 조회
  fetchAffiliateList(partnershipType?: PARTNERSHIP_TYPE): Promise<AxiosResponse<AffiliateListRs>>;

  //제휴 대학 리스트 조회
  fetchAffiliatedUniversities(name: string): Promise<AxiosResponse<AffiliatedUniversitiesRs>>;

  //제휴 대학여부 조회
  fetchAffiliatedUniversityCheck(schoolCode: number): Promise<AxiosResponse<PartnershipRs>>;

  //중복 학번여부 조회
  fetchDuplicateStudentIdCheck(schoolCode: number, studentId: string): Promise<AxiosResponse<DuplicatedRs>>;

  //회원 제휴 인증현황 조회
  fetchAffiliateCertificationStatus(): Promise<AxiosResponse<AllianceInfoRs>>;

  //제휴 기관 인증 정보 저장
  fetchSaveVerificationCodeInfo(code: string): Promise<AxiosResponse<boolean>>;

  //제휴 대학 인증 정보 저장
  fetchSaveAffiliatedUniversityInfo(data: AffiliatedUniversitySaveRq): Promise<AxiosResponse<boolean>>;

  //제휴 대학 인증 정보 수정
  fetchUpdateAffiliatedUniversity(data: AffiliatedUniversityUpdateRq): Promise<AxiosResponse<boolean>>;

  //제휴 기관 인증코드 인증
  fetchVerificationCodeCheck(code: string): Promise<AxiosResponse<boolean>>;

  //제휴 대학 이메일  인증
  fetchUniversityEmailCheck(email: string, token: string): Promise<AxiosResponse<boolean>>;

  //제휴 대학 이메일 인증코드 발송
  fetchUniversityEmailCodeSend(schoolCode: number, email: string): Promise<AxiosResponse<boolean>>;

  //제휴서비스 약관
  fetchPartnershipTermsList(termType: TERMS_TYPE): Promise<AxiosResponse<TermsOneRs>>;

  //제휴 인증 해지
  fetchDeletePartnershipUser(partnershipType: PARTNERSHIP_TYPE): Promise<AxiosResponse>;
}

export class RemotePartnershipRepo implements PartnershipRepo {
  fetchAffiliateList(partnershipType?: PARTNERSHIP_TYPE) {
    return axios.get<AffiliateListRs>(`${partnershipPrefix}`, { params: { partnershipType: partnershipType ?? '' } });
  }

  fetchAffiliatedUniversities(name: string) {
    return axios.get<AffiliatedUniversitiesRs>(`${partnershipPrefix}/universities`, { params: { name } });
  }

  fetchAffiliatedUniversityCheck(schoolCode: number) {
    return axios.get<PartnershipRs>(`${partnershipPrefix}/universities/${schoolCode}`);
  }

  fetchDuplicateStudentIdCheck(schoolCode: number, studentId: string) {
    return axios.get<DuplicatedRs>(`${partnershipPrefix}/universities/${schoolCode}/duplicate`, {
      params: { studentId },
    });
  }

  fetchAffiliateCertificationStatus() {
    return axios.get<AllianceInfoRs>(`${partnershipPrefix}/user/info`);
  }

  fetchSaveVerificationCodeInfo(code: string) {
    return axios.post<boolean>(`${partnershipPrefix}/user/register/organization`, { code });
  }

  fetchSaveAffiliatedUniversityInfo(data: AffiliatedUniversitySaveRq) {
    return axios.post<boolean>(`${partnershipPrefix}/user/register/universities`, { universityInfo: data });
  }

  fetchUpdateAffiliatedUniversity(data: AffiliatedUniversityUpdateRq) {
    return axios.put<boolean>(`/v2${partnershipPrefix}/user/universities`, data);
  }

  fetchVerificationCodeCheck(code: string) {
    return axios.post<boolean>(`${partnershipPrefix}/verification/organization/send`, { code });
  }

  fetchUniversityEmailCheck(email: string, token: string) {
    return axios.post<boolean>(`${partnershipPrefix}/verification/university/verify`, { email, token });
  }

  fetchUniversityEmailCodeSend(schoolCode: number, email: string) {
    return axios.post<boolean>(`${partnershipPrefix}/verification/university/send`, { schoolCode, email });
  }

  fetchPartnershipTermsList(termType: TERMS_TYPE): Promise<AxiosResponse<TermsOneRs>> {
    return axios.get('/partnership/terms', { params: { termType } });
  }

  fetchDeletePartnershipUser(partnershipType: PARTNERSHIP_TYPE) {
    return axios.delete('/partnership/user', { params: { partnershipType } });
  }
}
