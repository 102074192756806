import {
  AffiliatedUniversityInfoSaveVO,
  AffiliatedUniversityInfoUpdateVO,
} from '@domain/vo/alliance/AffiliatedUniversityInfoVO';

export default class AffiliatedUniversitySaveRq {
  schoolCode: number;
  majorCode: number;
  majorName: string;
  studentId: string;
  email: string;

  constructor(vo: AffiliatedUniversityInfoSaveVO) {
    this.schoolCode = typeof vo.school.value === 'string' ? parseInt(vo.school.value) : vo.school.value;
    this.majorCode = typeof vo.major.value === 'string' ? parseInt(vo.major.value) : vo.major.value;
    this.majorName = vo.major.label;
    this.studentId = vo.studentId;
    this.email = vo.email;
  }
}

export class AffiliatedUniversityUpdateRq {
  majorCode: number;
  majorName: string;
  studentId: string;

  constructor(vo: AffiliatedUniversityInfoUpdateVO) {
    this.majorCode = typeof vo.major.value === 'string' ? parseInt(vo.major.value) : vo.major.value;
    this.majorName = vo.major.label;
    this.studentId = vo.studentId;
  }
}
